

// Password Show Hide Js
function showHidePassword() {
    let showPassword = document.getElementById('passwordInp');
    let eyeBtn = document.querySelector('.eyeBtn');

    eyeBtn.classList.toggle('showPassword');
    let eleType = showPassword.getAttribute('type');
    if(eleType == 'password') {
        showPassword.setAttribute('type', 'text')
    } else {
        showPassword.setAttribute('type', 'password')
    }
    
}

// Otp auto shift js
$(".optField").keyup(function () {
    if (this.value.length == this.maxLength) {
      $(this).next('.optField').focus();
    }
});


// Sidebar Menu Toggle
function menuToggle() {
    let leftSideMenu = document.getElementById('leftSideMenu');
    let menuOverLay = document.getElementById('menuOverLay');
    let contentBlur = document.querySelector('.dashWapper__content');

    leftSideMenu.classList.toggle('opened')
    menuOverLay.classList.toggle('opened')
    contentBlur.classList.toggle('blured')
}